import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import {
  required,
  nonEmptyArray,
  validAgeRange,
  validWaitlistMonthYear,
  composeValidators,
} from '../../../../util/validators';
import range from 'lodash/range';
import {
  Button,
  Form,
  FieldTextInput,
  ExternalLink,
  H6,
  FieldCheckbox,
  FieldCheckboxGroup,
  FieldSelect,
  SecondaryButton,
} from '../../../../components';

import video from '../../../../assets/videos/programsAndAvailability.mp4';
import css from './EditListingProgramsAndAvailabilityForm.module.css';

export const availabilityOptions = intl => [
  {
    key: 'fullTime',
    label: intl.formatMessage({ id: 'EditListingProgramsAndAvailabilityForm.fullTime' }),
  },
  {
    key: 'partTime',
    label: intl.formatMessage({ id: 'EditListingProgramsAndAvailabilityForm.partTime' }),
  },
  {
    key: 'beforeSchool',
    label: intl.formatMessage({ id: 'EditListingProgramsAndAvailabilityForm.beforeSchool' }),
  },
  {
    key: 'weekend',
    label: intl.formatMessage({ id: 'EditListingProgramsAndAvailabilityForm.weekend' }),
  },
  {
    key: 'overnight',
    label: intl.formatMessage({ id: 'EditListingProgramsAndAvailabilityForm.overnight' }),
  },
];

export const EditListingProgramsAndAvailabilityFormComponent = props => {
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          formId,
          className,
          autoFocus,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          form,
          isExpired,
          isPro,
        } = formRenderProps;

        const classes = classNames(css.root, className, { [css.formDisabled]: !isPro });
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const { updateListingError, showListingsError } = fetchErrors || {};

        const preCreatedApplicationFormLink = (
          <ExternalLink
            href="https://docs.google.com/forms/d/1MFthDgU2wO4D4TvWcsrwRI6_CSMqDC4ZQ0Sm6gRjdJE/copy"
            className={css.preCreatedApplicationFormLink}
          >
            <FormattedMessage id="EditListingProgramsAndAvailabilityForm.clickHere" />
          </ExternalLink>
        );
        const preCreatedWaitlistFormLink = (
          <ExternalLink
            href="https://docs.google.com/forms/d/17dzmwIET0mQZNma3YGMMn3EAFNjRRRlbuoyXTV7aIYY/copy"
            className={css.preCreatedWaitlistFormLink}
          >
            <FormattedMessage id="EditListingProgramsAndAvailabilityForm.here" />
          </ExternalLink>
        );
        const waitListFormLink = (
          <div className={css.waitListFormLink}>
            <FormattedMessage
              id="EditListingProgramsAndAvailabilityForm.waitListFormLinks"
              values={{ preCreatedApplicationFormLink, preCreatedWaitlistFormLink }}
            />
          </div>
        );

        const nameValidator = required(
          intl.formatMessage({ id: 'EditListingProgramsAndAvailabilityForm.nameRequiredMessage' })
        );
        const ageValidValidator = i =>
          validAgeRange(
            i,
            intl.formatMessage({ id: 'EditListingProgramsAndAvailabilityForm.ageValidMessage' })
          );
        const ageRequiredValidator = required(
          intl.formatMessage({ id: 'EditListingProgramsAndAvailabilityForm.ageRequiredMessage' })
        );
        const ageValidator = i =>
          !!values.programs[i]?.age?.from && !!values.programs[i]?.age?.to
            ? ageValidValidator(i)
            : ageRequiredValidator;

        const availabilityValidator = required(
          intl.formatMessage({
            id: 'EditListingProgramsAndAvailabilityForm.availabilityRequiredMessage',
          })
        );
        const waitlistMonthYearValidator = i =>
          validWaitlistMonthYear(
            i,
            intl.formatMessage({
              id: 'EditListingProgramsAndAvailabilityForm.validWaitlistMonthYearMessage',
            })
          );
        const applyOptionValidator = required(
          intl.formatMessage({
            id: 'EditListingProgramsAndAvailabilityForm.applyOptionRequiredMessage',
          })
        );
        const customLinkValidator = required(
          intl.formatMessage({
            id: 'EditListingProgramsAndAvailabilityForm.customLinkRequiredMessage',
          })
        );
        const availableOptionsValidator = composeValidators(
          nonEmptyArray(
            intl.formatMessage({
              id: 'EditListingProgramsAndAvailabilityForm.availableOptionsRequiredMessage',
            })
          )
        );
        const descriptionValidator = required(
          intl.formatMessage({
            id: 'EditListingProgramsAndAvailabilityForm.descriptionRequiredMessage',
          })
        );
        const depositAmountValidator = required(
          intl.formatMessage({
            id: 'EditListingProgramsAndAvailabilityForm.depositAmountRequiredMessage',
          })
        );
        const monthlyFeeValidator = required(
          intl.formatMessage({
            id: 'EditListingProgramsAndAvailabilityForm.monthlyFeeRequiredMessage',
          })
        );
        const offeringValidator = composeValidators(
          nonEmptyArray(
            intl.formatMessage({
              id: 'EditListingProgramsAndAvailabilityForm.offeringRequiredMessage',
            })
          )
        );

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingProgramsAndAvailabilityForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingProgramsAndAvailabilityForm.showListingFailed" />
              </p>
            ) : null}

            <p className={css.description}>
              <FormattedMessage id="EditListingProgramsAndAvailabilityForm.description" />
            </p>
            {isExpired ? (
              <p className={css.expired}>
                <FormattedMessage id="EditListingProgramsAndAvailabilityForm.programsExpired" />
              </p>
            ) : null}

            <p className={css.videoTitle}>
              <FormattedMessage id="EditListingProgramsAndAvailabilityForm.videoTitle" />
            </p>
            <div className={css.videoContainer}>
              <video className={css.video} controls>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className={css.waitListForm}>{waitListFormLink}</div>
            </div>

            <FieldArray
              name="programs"
              validate={composeValidators(
                nonEmptyArray(
                  intl.formatMessage({
                    id: 'EditListingProgramsAndAvailabilityForm.programRequired',
                  })
                )
              )}
            >
              {({ fields }) =>
                fields.map((name, index) => {
                  const isWaitlist = values.programs[index]?.availability === 'waitList';
                  const isOpenSpots = values.programs[index]?.availability === 'openSpots';
                  const isExternalLink = values.programs[index]?.applyOption === 'externalLink';
                  return (
                    <div key={index} className={css.program}>
                      <div className={css.row}>
                        <div className={css.programName}>
                          <FieldTextInput
                            id={`${formId}.programs.${index}.name`}
                            name={`${name}.name`}
                            className={css.programNameInput}
                            autoFocus={autoFocus}
                            placeholder={intl.formatMessage({
                              id: 'EditListingProgramsAndAvailabilityForm.programNamePlaceholder',
                            })}
                            // validate={nameValidator}
                          />
                        </div>
                        <div className={css.ageWrapper}>
                          <div>
                            <FieldSelect
                              id={`${formId}.programs.${index}.ageFrom`}
                              name={`${name}.age.from`}
                              defaultValue=""
                              className={css.ageSelect}
                              validate={ageValidator(index)}
                            >
                              <option value="" disabled>
                                Age
                              </option>
                              <option value="0m">0m</option>
                              <option value="3m">3m</option>
                              <option value="6m">6m</option>
                              <option value="9m">9m</option>
                              <option value="12m">12m</option>
                              {range(2, 19).map(i => (
                                <option value={`${i}y`}>{`${i}y`}</option>
                              ))}
                            </FieldSelect>
                          </div>
                          <span className={css.ageTo}>to</span>
                          <div>
                            <FieldSelect
                              id={`${formId}.programs.${index}.ageTo`}
                              name={`${name}.age.to`}
                              defaultValue=""
                              className={css.ageSelect}
                              validate={ageValidator(index)}
                            >
                              <option value="" disabled>
                                Age
                              </option>
                              <option value="0m">0m</option>
                              <option value="3m">3m</option>
                              <option value="6m">6m</option>
                              <option value="9m">9m</option>
                              <option value="12m">12m</option>
                              {range(2, 19).map(i => (
                                <option value={`${i}y`}>{`${i}y`}</option>
                              ))}
                            </FieldSelect>
                          </div>
                        </div>
                        <div className={css.availabilityWrapper}>
                          <FieldSelect
                            id={`${formId}.programs.${index}.availability`}
                            name={`${name}.availability`}
                            validate={availabilityValidator}
                            className={isExpired ? css.availabilityExpired : css.availability}
                          >
                            <option value="" disabled>
                              {intl.formatMessage({
                                id: 'EditListingProgramsAndAvailabilityForm.availability',
                              })}
                            </option>
                            <option value="openSpots">
                              {intl.formatMessage({
                                id: 'EditListingProgramsAndAvailabilityForm.openSpots',
                              })}
                            </option>
                            <option value="waitList">
                              {intl.formatMessage({
                                id: 'EditListingProgramsAndAvailabilityForm.waitList',
                              })}
                            </option>
                            <option value="noAvailability">
                              {intl.formatMessage({
                                id: 'EditListingProgramsAndAvailabilityForm.noAvailability',
                              })}
                            </option>
                          </FieldSelect>
                        </div>
                      </div>

                      {isOpenSpots && (
                        <div className={css.row}>
                          <div>
                            <FieldCheckboxGroup
                              id={`programs[${[index]}][availabilityOptions]`}
                              className={css.checkboxContainer}
                              name={`${name}.availabilityOptions`}
                              label={intl.formatMessage({
                                id: 'ProgramsAndAvailabilityPage.available',
                              })}
                              options={availabilityOptions(intl)}
                              validate={availableOptionsValidator}
                            />
                          </div>
                        </div>
                      )}

                      {isWaitlist && (
                        <div>
                          <H6 className={css.monthyear}>
                            <FormattedMessage id="ProgramsAndAvailabilityPage.monthyear" />
                          </H6>

                          <p className={css.monthYearLabel}>
                            <FormattedMessage id="ProgramsAndAvailabilityPage.monthyearp" />
                          </p>
                          <div className={css.row}>
                            <FieldSelect
                              id={`${formId}.programs.${index}.waitlist.month`}
                              name={`${name}.waitlist.month`}
                              className={css.month}
                              placeholder={intl.formatMessage({
                                id:
                                  'EditListingProgramsAndAvailabilityForm.waitlistMonthPlaceholder',
                              })}
                              validate={waitlistMonthYearValidator(index)}
                            >
                              <option value="" disabled>
                                Month
                              </option>
                              {Array.from({ length: 12 }, (_, i) => (
                                <option key={i} value={i + 1}>
                                  {new Date(0, i).toLocaleString('default', { month: 'long' })}
                                </option>
                              ))}
                            </FieldSelect>
                            <FieldSelect
                              id={`${formId}.programs.${index}.waitlist.year`}
                              name={`${name}.waitlist.year`}
                              className={css.year}
                              placeholder={intl.formatMessage({
                                id:
                                  'EditListingProgramsAndAvailabilityForm.waitlistYearPlaceholder',
                              })}
                              validate={waitlistMonthYearValidator(index)}
                            >
                              <option value="" disabled>
                                Year
                              </option>
                              {Array.from({ length: 10 }, (_, i) => (
                                <option key={i} value={new Date().getFullYear() + i}>
                                  {new Date().getFullYear() + i}
                                </option>
                              ))}
                            </FieldSelect>
                          </div>
                        </div>
                      )}
                      {(isOpenSpots || isWaitlist) && (
                        <div>
                          <div>
                            <h6 className={css.openwaitlist}>
                              <FormattedMessage id="ProgramsAndAvailabilityPage.openwaitlist" />
                            </h6>
                          </div>
                          <div className={css.applyOptionContainer}>
                            <FieldSelect
                              id={`${formId}.programs.${index}.applyOption`}
                              name={`${name}.applyOption`}
                              rootClassName={css.applyOption}
                              label={intl.formatMessage({
                                id: 'ProgramsAndAvailabilityPage.openwaitlistp',
                              })}
                              validate={applyOptionValidator}
                            >
                              <option value="" disabled>
                                {intl.formatMessage({
                                  id: 'EditListingProgramsAndAvailabilityForm.selectOption',
                                })}
                              </option>
                              <option value="message">
                                {intl.formatMessage({
                                  id: 'EditListingProgramsAndAvailabilityForm.sendMessage',
                                })}
                              </option>
                              <option value="externalLink">
                                {intl.formatMessage({
                                  id: 'EditListingProgramsAndAvailabilityForm.useMyLink',
                                })}
                              </option>
                            </FieldSelect>

                            {isExternalLink && (
                              <>
                                <div className={css.waitListForm}>{waitListFormLink}</div>
                                <FieldTextInput
                                  id={`${formId}.programs.${index}.customLink`}
                                  name={`${name}.customLink`}
                                  className={css.customlink}
                                  placeholder={intl.formatMessage({
                                    id:
                                      'EditListingProgramsAndAvailabilityForm.customLinkPlaceholder',
                                  })}
                                  validate={customLinkValidator}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      <div className={css.row}>
                        <FieldTextInput
                          type="textarea"
                          id={`${formId}.programs.${index}.description`}
                          name={`${name}.description`}
                          className={css.description}
                          placeholder={intl.formatMessage({
                            id: 'EditListingProgramsAndAvailabilityForm.descriptionPlaceholder',
                          })}
                          validate={descriptionValidator}
                        />
                      </div>
                      <div className={css.row}>
                        <FieldTextInput
                          id={`${formId}.programs.${index}.depositAmount`}
                          name={`${name}.depositAmount`}
                          className={css.depositamount}
                          placeholder={intl.formatMessage({
                            id: 'EditListingProgramsAndAvailabilityForm.depositAmountPlaceholder',
                          })}
                          validate={depositAmountValidator}
                          type="number"
                        />
                        <FieldTextInput
                          id={`${formId}.programs.${index}.monthlyFee`}
                          name={`${name}.monthlyFee`}
                          className={css.monthlyFee}
                          placeholder={intl.formatMessage({
                            id: 'EditListingProgramsAndAvailabilityForm.monthlyFeePlaceholder',
                          })}
                          validate={monthlyFeeValidator}
                        />
                      </div>

                      <div className={css.row}>
                        <h6 className={css.available}>
                          <FormattedMessage id="ProgramsAndAvailabilityPage.offering" />
                        </h6>
                        <div className={css.checkboxContainer}>
                          <div className={css.checkboxItem}>
                            <FieldCheckbox
                              id={`subsidy-${index}`}
                              name={`${name}.offering`}
                              label={intl.formatMessage({
                                id: 'EditListingProgramsAndAvailabilityForm.subsidy',
                              })}
                              value="subsidy"
                              validate={offeringValidator}
                            />
                          </div>
                          <div className={css.checkboxItem}>
                            <FieldCheckbox
                              id={`grant-${index}`}
                              name={`${name}.offering`}
                              label={intl.formatMessage({
                                id: 'EditListingProgramsAndAvailabilityForm.grant',
                              })}
                              value="grant"
                              validate={offeringValidator}
                            />
                          </div>
                        </div>
                      </div>
                      {index === 0 ? null : (
                        <div className={css.removeProgram}>
                          <button
                            type="button"
                            onClick={() => fields.remove(index)}
                            className={css.removeProgramBtn}
                          >
                            {intl.formatMessage({
                              id: 'EditListingProgramsAndAvailabilityForm.removeProgram',
                            })}
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })
              }
            </FieldArray>

            <div className={css.addProgramButtonWrapper}>
              <SecondaryButton
                type="button"
                onClick={() => form.mutators.push('programs', {})}
                disabled={disabled}
                className={css.addProgramButton}
              >
                <FormattedMessage id="EditListingProgramsAndAvailabilityForm.addAnother" />
              </SecondaryButton>
            </div>
            <div className={css.submitButtonWrapper}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingProgramsAndAvailabilityFormComponent.propTypes = {
  formId: string.isRequired,
  className: string,
  autoFocus: bool,
  disabled: bool,
  ready: bool,
  handleSubmit: func.isRequired,
  intl: intlShape.isRequired,
  invalid: bool.isRequired,
  pristine: bool.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    updateListingError: string,
    showListingsError: string,
  }),
};

export default compose(injectIntl)(EditListingProgramsAndAvailabilityFormComponent);
