import React from 'react';
import { ExternalLink, SecondaryButton, IconLink, IconPhone, IconEmail } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionTextMaybe = props => {
  const { publicData } = props;
  const { website, phoneNumber, email } = publicData;

  const websiteButton = website && (
    <div className={css.cta}>
      <ExternalLink href={website} target="_blank" className={css.websiteButton}>
        <FormattedMessage id="ListingPage.visitWebsite" />
        <IconLink />
      </ExternalLink>
    </div>
  );

  const callButton = phoneNumber && (
    <div className={css.cta}>
      <SecondaryButton
        onClick={() => {
          window.location.href = `tel:${phoneNumber}`;
        }}
        className={css.callButton}
      >
        <FormattedMessage id="ListingPage.call" />
        <IconPhone />
      </SecondaryButton>
    </div>
  );

  const emailButton = email && (
    <div className={css.cta}>
      <ExternalLink href={`mailto:${email}`} target="_self" className={css.emailButton}>
        <FormattedMessage id="ListingPage.email" />
        <IconEmail />
      </ExternalLink>
    </div>
  );

  return (
    <section className={css.sectionCtas}>
      {websiteButton}
      {callButton}
      {emailButton}
    </section>
  );
};

export default SectionTextMaybe;
